import React, { useState, useEffect, useContext,useCallback } from 'react';
import axios from 'axios';
import './WorkReportRobotics.css';
import { AuthContext } from '../context/AuthContext'; // Assuming AuthContext is set up correctly
import { format } from 'date-fns';
import Loader from '../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faTrash,faSave } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';

const ManageWorkReport = () => {
    const [regions, setRegions] = useState([]);
    const [schools, setSchools] = useState([]);
    const [classes, setClasses] = useState([]);
    const [batches, setBatches] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedSchool, setSelectedSchool] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [selectedTopic, setSelectedTopic] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [reports, setReports] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [description, setDescription] = useState('');
    const [message, setMessage] = useState('');
    const {userDetails} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [editReportId, setEditReportId] = useState(null);
    const [editDescription, setEditDescription] = useState('');
    const [selectedSchoolName, setSelectedSchoolName]=useState('');
    const [selectedClassName, setSelectedClassName]=useState('');
    const [selectedRegionName, setSelectedRegionName]=useState('');
    const [selectedBatchName, setSelectedBatchName]=useState('');


    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage('');
            }, 5000);  
            return () => clearTimeout(timer);
        }
    }, [message]);
    
    // Effect for fetching regions based on user role
    useEffect(() => {
        const fetchRegions = async () => {
            try {
                let url = `${process.env.REACT_APP_API_URL}/regions`;
                console.log("User Role:", userDetails.role);  // Debugging
                console.log("Assigned Regions:", userDetails.assignedRegion);  // Debug
                if (userDetails.role === 'VIEWER' && userDetails.assignedRegion.length >=0) {
                    url += `?ids=${userDetails.assignedRegion.join(',')||null}`;
                }
                const response = await axios.get(url);
                setRegions(response.data);
            } catch (error) {
                console.error('Failed to fetch regions:', error);
            }
        };
        fetchRegions();
    }, [userDetails.role, userDetails.assignedRegion]);

    // Effect for fetching schools based on selected region
    useEffect(() => {
        if (selectedRegion) {
            const fetchSchools = async () => {
                try {
                    let url = `${process.env.REACT_APP_API_URL}/schools?region_id=${selectedRegion}`;
                    if (userDetails.role === 'VIEWER' && userDetails.assignedSchool.length > 0) {
                        url += `&ids=${userDetails.assignedSchool.join(',')}`;
                    }
                    const response = await axios.get(url);
                    setSchools(response.data);
                } catch (error) {
                    console.error('Failed to fetch schools:', error);
                }
            };
            fetchSchools();
        }
    }, [selectedRegion, userDetails.role, userDetails.assignedSchool]);

    useEffect(() => {
        if (selectedSchool) {
            axios.get(`${process.env.REACT_APP_API_URL}/classes?school_id=${selectedSchool}`)
                .then(response => setClasses(response.data))
                .catch(error => console.error('Failed to fetch classes:', error));
        }
    }, [selectedSchool]);

    useEffect(() => {
        if (selectedClass) {
            axios.get(`${process.env.REACT_APP_API_URL}/batches?class_id=${selectedClass}`)
                .then(response => setBatches(response.data))
                .catch(error => console.error('Failed to fetch batches:', error));
        }
    }, [selectedClass]);

    const fetchReports = useCallback(async () => {
        try {
            
            setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/robotics-work-reports?batch_id=${encodeURIComponent(selectedBatch)}&date=${selectedDate}`);
            if (response.data.message === "No reports found for the selected batch and date") {
                setReports([]); // Explicitly set reports to an empty array
            } else {
                const reportData = await Promise.all(response.data.map(async (report) => {
                    console.log("Encoded batchId => ", encodeURIComponent(report.topic_id));
                    const topicResponse = await axios.get(`${process.env.REACT_APP_API_URL}/topics/${encodeURIComponent(report.topic_id)}`);
                    return {
                        ...report,
                        topic_name: topicResponse.data.topic_name,
                    };
                }));
            setReports(reportData);
            }
        } catch (error) {
            console.error('Failed to fetch reports:', error);
            setReports([]);
        }finally {
            setIsLoading(false);  // End loading
        }
    }, [selectedBatch, selectedDate]);
    
    useEffect(() => {
        if (selectedBatch && selectedDate) {
            fetchReports();
        }
    }, [selectedBatch, selectedDate, fetchReports]); // Now fetchReports is stable and can be safely included as a dependency
      

    useEffect(() => {
        if (selectedBatch) {
            console.log("Encoded batchId 2 => ", encodeURIComponent(selectedBatch));
            axios.get(`${process.env.REACT_APP_API_URL}/topics?class_id=${selectedClass}&batch_id=${encodeURIComponent(selectedBatch)}`)
                .then(response => setTopics(response.data))
                .catch(error => console.error('Failed to fetch topics:', error));
        }
    }, [selectedBatch, selectedClass]);

    const toggleEdit = (report) => {
        setEditReportId(report.report_id);
        setEditDescription(report.description);
    };

    const handleUpdateDescription = async (reportId) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/robotics-work-reports/${reportId}`, {
                description: editDescription,
            });
            const updatedReports = reports.map(report => {
                if (report.report_id === reportId) {
                    return { ...report, description: editDescription };
                }
                return report;
            });
            setReports(updatedReports);
            setEditReportId(null); // Exit edit mode
        } catch (error) {
            console.error('Failed to update description:', error);
        }
    };

    const handleAddDescription = () => {
        if (!selectedRegion || !selectedSchool || !selectedClass || !selectedBatch || !selectedTopic || !userDetails.email) {
            console.error('Missing data in one of the fields');
            return;
        }
        
        axios.post(`${process.env.REACT_APP_API_URL}/robotics-work-reports`, {
            region_id: selectedRegion,
            school_id: selectedSchool,
            class_id: selectedClass,
            batch_id: selectedBatch,
            topic_id: selectedTopic,
            date: selectedDate,
            description,
            reported_by: userDetails.email, // Use the email from the auth context
        })
        .then(response => {
            setMessage('Description added successfully');
            // Fetch the topic name for the new report
            const topic = topics.find(t => t.topic_id === selectedTopic);
            const newReport = {
                ...response.data,
                topic_name: topic ? topic.topic_name : 'Unknown' // Safeguard in case topic isn't found
            };
            setReports([...reports, newReport]);
            setShowModal(false);
        })
            .catch(error => {
                console.error('Failed to add report:', error);
                setMessage('Failed to add description');
            });
    };

    const handleDeleteReport = (report_id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/robotics-work-reports/${report_id}`)
            .then(response => {
                setMessage('Report deleted successfully');
                setReports(reports.filter(report => report.report_id !== report_id));
            })
            .catch(error => {
                console.error('Failed to delete report:', error);
                setMessage('Failed to delete report');
            });
    };

    const renderTable = () => {
        if (!selectedBatch || !selectedDate) {
            return <p>VALID SELECTIONS ARE NOT MADE FOR DATA TO DISPLAY</p>;
        }

        if (reports.length === 0) {
            return <p>NO DATA TO DISPLAY</p>;
        }

        return (
            <div className="table-wrapper-WorkReportRobotics">
            <table className="reports-table-WorkReportRobotics">
                <thead>
                    <tr>
                        <th>Topic</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Actions</th>
                        <th>Reported by</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.map((report) => (
                        <tr key={report.report_id}>
                            <td>{report.topic_name}</td>
                            <td>{format(new Date(report.date), 'dd-MM-yyyy')}</td>
                            <td className={report.description.length > 20 ? 'long-topic-WorkReportRobotics' : ''}>{editReportId === report.report_id ? (
                                    <textarea
                                        value={editDescription}
                                        onChange={e => setEditDescription(e.target.value)}
                                    />
                                ) : (
                                    report.description
                                )}</td>
                            <td>
                                {editReportId === report.report_id ? (
                                <button className='edit-WorkReportRobotics' onClick={() => handleUpdateDescription(report.report_id)}>
                                <FontAwesomeIcon icon={faSave} />
                                </button>
                                ):(
                                <button className='edit-WorkReportRobotics' onClick={() => toggleEdit(report)} disabled={format(new Date(report.date), 'dd-MM-yyyy') !== format(new Date(), 'dd-MM-yyyy') || userDetails.email !== report.reported_by}><FontAwesomeIcon icon={faEdit} /></button>
                                )}
                                <button className='delete-WorkReportRobotics' onClick={() => handleDeleteReport(report.report_id)} disabled={format(new Date(report.date), 'dd-MM-yyyy') !== format(new Date(), 'dd-MM-yyyy') || userDetails.email !== report.reported_by}><FontAwesomeIcon icon={faTrash} /></button>
                            </td>
                            <td className={report.reported_by.length > 20 ? 'long-topic-WorkReportRobotics' : ''}>{report.reported_by}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        );
    };

    const handleDownloadExcel = () => {
        const worksheetData = [
            ["Region Name", selectedRegionName], // Using the state directly
            ["School Name", selectedSchoolName], // Using the state directly
            ["Class Name", selectedClassName],   // Using the state directly
            ["Batch Name", selectedBatchName],   // Using the state directly
            [],                                  // Empty row to separate header from data
            ["S.No.", "Topic", "Date", "Description", "Reported by"] // Column headers for the table
        ];
    
        // Assuming 'reports' is an array of report objects you want to include in the Excel file
        reports.forEach((report, index) => {
            worksheetData.push([
                index + 1,                        // S.No.
                report.topic_name,                // Topic
                format(new Date(report.date), 'dd-MM-yyyy'), // Date
                report.description,               // Description
                report.reported_by                // Reported by
            ]);
        });
    
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Work Reports');
        XLSX.writeFile(workbook, `Work_Reports_${new Date().toLocaleDateString()}.xlsx`);
    };

    // Handler for Region Selection
    const handleRegionChange = (event) => {
        const regionId = event.target.value;
        const region = regions.find(r => r.region_id === regionId);
        setSelectedRegion(regionId);
        setSelectedRegionName(region ? region.region_name : '');
    };

    // Handler for School Selection
    const handleSchoolChange = (event) => {
        const schoolId = event.target.value;
        const school = schools.find(s => s.school_id === schoolId);
        setSelectedSchool(schoolId);
        setSelectedSchoolName(school ? school.school_name : '');
    };

    // Handler for Class Selection
    const handleClassChange = (event) => {
        const classId = event.target.value;
        const cls = classes.find(c => c.class_id === classId);
        setSelectedClass(classId);
        setSelectedClassName(cls ? cls.class_name : '');
    };

    // Handler for Batch Selection
    const handleBatchChange = (event) => {
        const batchId = event.target.value;
        const batch = batches.find(b => b.batch_id === batchId);
        setSelectedBatch(batchId);
        setSelectedBatchName(batch ? batch.batch_name : '');
    };

    return (
        <div className="work-report-container">
        
            {message && (
                <div className="message-box">
                    <p>{message}</p>
                    <button onClick={() => setMessage('')}>Close</button>
                </div>
            )}
            <h1>Work Report Entry</h1>
            <div className="dropdown-row-WorkReportRobotics">
                <select onChange={handleRegionChange} value={selectedRegion}>
                    <option value="">Region</option>
                    {regions.map(region => (
                        <option key={region.region_id} value={region.region_id}>
                            {region.region_name}
                        </option>
                    ))}
                </select>
                <select onChange={handleSchoolChange} value={selectedSchool} disabled={!selectedRegion}>
                    <option value="">School</option>
                    {schools.map(school => (
                        <option key={school.school_id} value={school.school_id}>
                            {school.school_name}
                        </option>
                    ))}
                </select>
                <select onChange={handleClassChange} value={selectedClass} disabled={!selectedSchool}>
                    <option value="">Class</option>
                    {classes.map(cls => (
                        <option key={cls.class_id} value={cls.class_id}>
                            {cls.class_name}
                        </option>
                    ))}
                </select>
                <select onChange={handleBatchChange} value={selectedBatch} disabled={!selectedClass}>
                    <option value="">Batch</option>
                    {batches.map(batch => (
                        <option key={batch.batch_id} value={batch.batch_id}>
                            {batch.batch_name}
                        </option>
                    ))}
                </select>
                <select onChange={(e) => setSelectedTopic(e.target.value)} value={selectedTopic} disabled={!selectedBatch}>
                    <option value="">Topic</option>
                    {topics.map(topic => (
                        <option key={topic.topic_id} value={topic.topic_id}>
                            {topic.topic_name}
                        </option>
                    ))}
                </select>
                <label id="dateInputLabel">Select Date:</label>
                <input type="date" onChange={(e) => setSelectedDate(e.target.value)} value={selectedDate} />
            </div>
            <div className="buttons-row-WorkReportRobotics">
                <button className="add-description-button-WorkReportRobotics" onClick={() => setShowModal(true)}>Add Description</button>
                <button className="export-button-WorkReportRobotics" onClick={() => handleDownloadExcel()}><span><FontAwesomeIcon icon={faDownload} /></span> Excel</button>
            </div>
            {isLoading ? <Loader /> : renderTable()}

            {showModal && (
                <div className="modal-overlay-WorkReportRobotics">
                    <div className="modal-content-WorkReportRobotics">
                        <h2>Add Description</h2>
                        <textarea
                            placeholder="Enter description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="modal-input-WorkReportRobotics"
                        />
                        <div className="modal-buttons-WorkReportRobotics">
                            <button onClick={handleAddDescription} className="modal-save-button-WorkReportRobotics">Save</button>
                            <button onClick={() => setShowModal(false)} className="modal-close-button-WorkReportRobotics">Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageWorkReport;
