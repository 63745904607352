// Fisrt User of this page : girirahul19.99.99@gmail.com   ADMISSION NUMBER : 303-22   SCHOOL: UCEMSP CITY

import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "./Footer";
import "./QueryTicketForm.css";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const QueryTicketForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    admission: "",
    school: "",
    queryType: "",
    message: "",
    userType: "",
    prefferedMode: "",
    document: null,
    documentName: "Attach a Supporting Document (Optional)",
  });

  // State to store and display errors
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [schools, setSchools] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [schoolSearch, setSchoolSearch] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // For displaying success/failure messages
  const [loading, setLoading] = useState(false);

  // Fetch schools upon typing in the search input, debounced
  useEffect(() => {
    const fetchSchools = async () => {
      if (schoolSearch.length >= 2) {
        try {
          const response = await axios.get(`${API_BASE_URL}/search-schools`, {
            params: {
              query: schoolSearch,
            },
          });
          setFilteredSchools(response.data);
        } catch (error) {
          console.error("Error fetching schools:", error);
        }
      } else {
        setFilteredSchools([]);
      }
    };

    const delayDebounceFn = setTimeout(() => {
      fetchSchools();
    }, 300); // Debounce by 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [schoolSearch]);


  const fetchAllSchools = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/view-schools`);
      setSchools(response.data);
      setFilteredSchools(response.data);
    } catch (error) {
      console.error("Error fetching schools:", error);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error for that field if user starts typing
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  // Handle file changes
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({
      ...prev,
      document: file,
      documentName: file ? file.name : "Upload Supporting Document (Optional)",
    }));
  };

  // User selects a school from the dropdown
  const handleSelectSchool = (schoolId, schoolName) => {
    setFormData((prev) => ({ ...prev, school: schoolId }));
    setSchoolSearch(schoolName);
    setIsDropdownOpen(false);
    // Clear error if previously set
    setErrors((prev) => ({ ...prev, school: "" }));
  };

  // Email validation function
  const validateEmail = (email) => {
    // Basic email pattern check
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Function to handle the pop-up display
  const triggerPopup = () => {
    setShowPopup(true);
  };

  // Form submission with validation
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); // Reset any previous errors
    let newErrors = {};

    // 1. Validate name
    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    }

    // 2. Validate email
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // 3. Validate phone (must be exactly 10 digits)
    if (!formData.phone.trim()) {
      newErrors.phone = "Mobile number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Mobile number must be exactly 10 digits.";
    }

    // 4. Validate userType
    if (!formData.userType) {
      newErrors.userType = "Please select a user type.";
    }

    // 5. If the user is Parent or Student, admission is required
    if ((formData.userType === "Parent" || formData.userType === "Student" || formData.userType==="Employee") && !formData.admission.trim()) {
      newErrors.admission = "Admission / Employee Number is required.";
    }

    // 6. Validate school
    if (!formData.school) {
      newErrors.school = "Please select a valid school from the dropdown.";
    }

    // 7. Validate queryType
    if (!formData.queryType) {
      newErrors.queryType = "Please select a query type.";
    }

    // 8. Validate message
    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
    }

    // If errors exist, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Proceed with API call if no errors
    setLoading(true);

    const formPayload = new FormData();
    formPayload.append("name", formData.name);
    formPayload.append("email", formData.email);
    formPayload.append("phone", formData.phone);
    formPayload.append("admission", formData.admission);
    formPayload.append("school", formData.school);
    formPayload.append("queryType", formData.queryType);
    formPayload.append("message", formData.message);
    formPayload.append("userType", formData.userType);
    formPayload.append("prefferedMode", null);
    console.log(formData.queryType);

    if (formData.document) {
      formPayload.append("document", formData.document);
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/submit-query`, formPayload, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Query submitted successfully:", response.data);
      triggerPopup();
      setFormData({
        name: "",
        email: "",
        phone: "",
        admission: "",
        school: "",
        queryType: "",
        message: "",
        userType: "",
        prefferedMode: "",
        document: null,
        documentName: "Attach a Supporting Document (Optional)",
      });
      setSchoolSearch("");
    } catch (error) {
      console.error("Error submitting query:", error);
      setFormData({
        name: "",
        email: "",
        phone: "",
        admission: "",
        school: "",
        queryType: "",
        message: "",
        userType: "",
        prefferedMode: "",
        document: null,
        documentName: "Attach a Supporting Document (Optional)",
      });
      setSchoolSearch("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="query-ticket-contact-container">
      <div className={`pop-up ${showPopup ? "show" : ""}`}>
        <div class="bulb">
              <svg width="60pt" height="60pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="m930 552.86h-660c-6.9297 0-13.191-4.168-15.836-10.582-2.6602-6.4102-1.1875-13.777 3.7148-18.684l289.72-289.7c4.1367-4.1367 10.078-5.875 15.836-4.6211 5.707 1.2734 10.379 5.375 12.387 10.883 3.7031 10.164 13.43 16.996 24.176 16.996s20.473-6.832 24.191-17.008c2.0078-5.5078 6.6797-9.5938 12.406-10.863 5.6602-1.2539 11.668 0.48437 15.82 4.6211l289.7 289.7c4.9062 4.9062 6.3789 12.27 3.7148 18.684-2.6445 6.4062-8.9062 10.574-15.836 10.574zm-618.62-34.285h577.23l-245.5-245.51c-11.102 11.469-26.586 18.367-43.109 18.367-16.539 0-32.008-6.8984-43.109-18.348z"></path>
          <path d="m986.48 1028.6h-772.97c-23.203 0-42.086-18.883-42.086-42.086v-425.82c0-23.203 18.883-42.09 42.086-42.09h772.97c23.203 0 42.086 18.887 42.086 42.09v425.82c0 23.203-18.883 42.086-42.086 42.086zm-772.97-475.71c-4.3008 0-7.8008 3.5-7.8008 7.8047v425.82c0 4.3008 3.5 7.8008 7.8008 7.8008h772.97c4.3008 0 7.8008-3.5 7.8008-7.8008v-425.82c0-4.3047-3.5-7.8047-7.8008-7.8047z"></path>
          <path d="m599.92 935.74c-89.332 0-162-72.758-162-162.17 0-89.414 72.672-162.17 162-162.17 89.414 0 162.17 72.758 162.17 162.17-0.003906 89.414-72.758 162.17-162.17 162.17zm0-290.05c-70.43 0-127.72 57.371-127.72 127.89 0 70.512 57.289 127.89 127.72 127.89 70.512 0 127.89-57.371 127.89-127.89-0.003907-70.516-57.375-127.89-127.89-127.89z"></path>
          <path d="m576.71 838.07c-4.5547 0-8.9062-1.8086-12.121-5.0234l-48.113-48.129c-6.6953-6.6953-6.6953-17.543 0-24.242 6.6953-6.6953 17.543-6.6953 24.242 0l35.992 36.012 82.582-82.582c6.6953-6.6953 17.543-6.6953 24.242 0 6.6953 6.6953 6.6953 17.543 0 24.242l-94.703 94.703c-3.2148 3.2109-7.5703 5.0195-12.121 5.0195z"></path>
          <path d="m600 291.43c-25.113 0-47.777-15.887-56.383-39.559-2.3633-6.1445-3.6172-13.125-3.6172-20.441 0-33.082 26.918-60 60-60s60 26.918 60 60c0 7.3477-1.2383 14.312-3.6992 20.727-8.5391 23.387-31.207 39.273-56.301 39.273zm0-85.715c-14.18 0-25.715 11.535-25.715 25.715 0 3.0625 0.50391 5.9766 1.4414 8.4375 3.8008 10.445 13.527 17.277 24.273 17.277s20.473-6.832 24.191-17.008c1.0391-2.6992 1.5234-5.6094 1.5234-8.707 0-14.18-11.535-25.715-25.715-25.715z"></path>
        </g>
        </svg>
        </div>
        <p>Query submitted successfully! Ticket ID has been mailed to you.</p>
        <button onClick={()=>setShowPopup(false)}>OK</button>
      </div>
      {/* Remove separate card background; place form on the main background */}
      <div className="query-ticket-form-wrapper animate-fadein">
        <h2>Query Form</h2>
        <form onSubmit={handleSubmit}>
          {/* Name, Email, Phone */}
          <div className="query-ticket-form-group">
            <div className="query-ticket-input-container">
              <label htmlFor="name">Your Name*</label>
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>

            <div className="query-ticket-input-container">
              <label htmlFor="email">Your Email Address *</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>

            <div className="query-ticket-input-container">
              <label htmlFor="phone">Your Mobile Number *</label>
              <input
                type="text"
                name="phone"
                placeholder="Contact number"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <span className="error">{errors.phone}</span>}
            </div>
          </div>

          {/* User Type, Admission Number */}
          <div className="query-ticket-form-group">
            <div className="query-ticket-input-container">
              <label htmlFor="userType">Who Are You? *</label>
              <select name="userType" value={formData.userType} onChange={handleChange}>
                <option value="" disabled>
                Who Are You ?
                </option>
                <option value="Employee">Employee</option>
                <option value="Parent">Parent</option>
                <option value="Student">Student</option>
                <option value="Guest">Guest</option>
              </select>
              {errors.userType && <span className="error">{errors.userType}</span>}
            </div>

            <div className="query-ticket-input-container">
              <label htmlFor="admission">Your Admission/Employee Number *</label>
              <input
                type="text"
                name="admission"
                placeholder={formData.userType==="Guest"?"This field is not required for you.":"Your Admission/Employee Number"}
                value={formData.admission}
                onChange={handleChange}
                disabled={formData.userType === "Guest"}
              />
              {errors.admission && <span className="error">{errors.admission}</span>}
            </div>
          </div>

          {/* School, Query Type */}
          <div className="query-ticket-form-group">
            <div className="query-ticket-input-container">
              <label htmlFor="school">Your School Name ? *</label>
              <div className="custom-select-container">
                <input
                  type="text"
                  placeholder="Search and select your school name or Others"
                  value={schoolSearch}
                  onClick={() => setIsDropdownOpen(true)}
                  onChange={(e) => setSchoolSearch(e.target.value)}
                  className="custom-select-input-form"
                />
                {isDropdownOpen && filteredSchools.length > 0 && (
                  <div className="custom-select-options-ticket">
                    {filteredSchools.map((school) => (
                      <div
                        key={school.school_id}
                        onClick={() => handleSelectSchool(school.school_id, school.school_name)}
                        className="custom-select-option"
                      >
                        {school.school_name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {errors.school && <span className="error">{errors.school}</span>}
            </div>

            <div className="query-ticket-input-container">
              <label htmlFor="queryType">What Is Your Query About? *</label>
              <select name="queryType" value={formData.queryType} onChange={handleChange}>
                <option value="" disabled>
                What Is Your Query About?
                </option>
                <option value="Issues with Online Payments">Issues with Online Payments</option>
                <option value="Login Problems on Mobile App">Login Problems on Mobile App</option>
                <option value="Forgot Password / Reset Password">Forgot Password / Reset Password</option>
                <option value="Change Mobile Number">Change Mobile Number</option>
                <option value="Modify Personal Details">Modify Personal Details</option>
                <option value="Admission Form Related Query">Admission Form Related Query</option>
                <option value="Report Card Related Query">Report Card Related Query</option>
                <option value="Technical Assistance">Technical Assistance</option>
                <option value="Other Inquiries / General Assistance">Other Inquiries / General Assistance</option>
              </select>
              {errors.queryType && <span className="error">{errors.queryType}</span>}
            </div>
          </div>

          {/* Message */}
          <div className="query-ticket-input-container">
            <label htmlFor="message">Details of Your Query</label>
            <textarea
              name="message"
              placeholder="Describe your query"
              value={formData.message}
              onChange={handleChange}
            />
            {errors.message && <span className="error">{errors.message}</span>}
          </div>

          {/* Document Upload */}
          <div className="query-ticket-input-container">
            <label htmlFor="document" className="file-upload-container">
              <span className="file-upload-title">{formData.documentName}</span>
              <input
                type="file"
                id="document"
                name="document"
                onChange={handleFileChange}
                accept=".pdf,.jpg,.png"
                className="file-upload-input"
              />
            </label>
            <p className="file-upload-instructions">
              Supported formats: PDF or Image files up to 10 MB only.
            </p>
          </div>

          {/* Submit Button */}
          <button type="submit" className="query-ticket-submit-button" disabled={loading}>
            {loading ? (
              <>
                <div className="querybuttonloader"></div>Submitting
              </>
            ) : (
              "Submit Query"
            )}
          </button>
        </form>
      </div>
      <Footer/>
    </div>
  );
};

export default QueryTicketForm;
