import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import './Auth.css';
const API_BASE_URL = process.env.REACT_APP_API_URL;
const Register = () => {
  const { setAuth } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${API_BASE_URL}/auth/register`, { name, email, password, mobile });
      localStorage.setItem('token', res.data.token);
      setAuth(true);
      alert(res.data.msg); // Show user-friendly message
    } catch (err) {
      console.error(err);
      alert('Registration failed. Please try again.'); // Handle registration errors
    }
  };

  return (
    <div className="auth-container">
      <form onSubmit={handleSubmit}>
        <h2>Digital Valley</h2>
        <div className="inputbox">
        <input
          type="text"
          className="input"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        </div>
        <div className="inputbox">
        <input
          type="email"
          className="input"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        </div>
        <div className="inputbox">
        <input
          type="password"
          className="input"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        </div>
        <div className="inputbox">
          <input
            type="text"
            className="input"
            placeholder="Mobile Number (10 Digits only)"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            maxLength="10"
            required
          />
        </div>
        <span className='instruction'><p><em>Mobile number</em> and <em>Email</em> is your identification. Fill wisely!</p></span>
        <button type="submit" className="submit">Register</button>
        <div className="olduser">
        <span>Already have an account? <Link className="login" to="/login">Login</Link></span>
        </div>
      </form>
    </div>
  );
};

export default Register;
