import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
// Import FontAwesome and the X mark
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faFile, faTimes } from "@fortawesome/free-solid-svg-icons";

import "./QueryChatCustomer.css";
import socket from "./socket";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const QueryChatCustomer = () => {
  // Read the encrypted ticket number from the URL query string
  const [searchParams] = useSearchParams();

  // Ticket data + chat states
  const [ticket, setTicket] = useState(null);

  // Chat messages
  const [messageHistory, setMessageHistory] = useState([]);
  const [messageContent, setMessageContent] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);

  // Loading states
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // Pagination / infinite scroll states
  const [messagePage, setMessagePage] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);

  // Reference to the messages container for scroll handling
  const messageHistoryRef = useRef(null);

  // State for mobile/tablet view: show or hide ticket details modal
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const ticket_id = searchParams.get("ticketNumber"); // Use ticket_id directly

  const linkify = (text) => {
    const urlPattern = /(\b(https?|http?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlPattern, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
  };

  // Effect to connect to socket, listen for new messages, and join the room
  useEffect(() => {
    console.log("QueryChatCustomer mounting. ticket_id:", ticket_id);
    socket.on("connect", () => {
      console.log("Customer connected to socket:", socket.id);
    });
    // 1) Connect to socket & listen for newMessage
    socket.on("newMessage", (data) => {
      // Check if the incoming message is for *this* ticket
      if (data.ticket_id === ticket_id) {
        setMessageHistory((prev) => [...prev, data.message]);
        // Optionally scroll to bottom
        if (messageHistoryRef.current) {
          setTimeout(() => {
            messageHistoryRef.current.scrollTop = messageHistoryRef.current.scrollHeight;
          }, 0);
        }
      }
    });

    // 2) Optionally join a "room" specific to this ticket
    socket.emit("joinTicketRoom", ticket_id);

    return () => {
      // Cleanup: leave the room, remove event listeners
      socket.emit("leaveTicketRoom", ticket_id);
      socket.off("newMessage");
    };
  }, [ticket_id]);

  // 1. On mount / whenever `encryptedTicketNumber` changes
  useEffect(() => {
    const fetchTicketData = async () => {
      if (!ticket_id) return;

      try {
        setIsLoadingMessages(true);
        setMessageHistory([]);
        setMessagePage(1);
        setHasMoreMessages(true);

        // Get ticket + first batch of messages
        const res = await axios.get(
          `${API_BASE_URL}/public/tickets/customer-view`,
          {
            params: { ticket_id },
          }
        );

        const { ticket, messages, totalMessages } = res.data;
        setTicket(ticket);

        // Assume messages are oldest -> newest. Adjust if needed.
        setMessageHistory(messages);

        // If we've fetched all messages, mark no more
        if (messages.length >= totalMessages) {
          setHasMoreMessages(false);
        }

        // Scroll to bottom after messages load
        setTimeout(() => {
          if (messageHistoryRef.current) {
            messageHistoryRef.current.scrollTop =
              messageHistoryRef.current.scrollHeight;
          }
        }, 0);
      } catch (err) {
        console.error("Error fetching ticket:", err);
      } finally {
        setIsLoadingMessages(false);
      }
    };

    fetchTicketData();
  }, [ticket_id]);

  // 2. Handle scrolling for infinite scroll (load older messages)
  const handleMessageScroll = () => {
    if (
      messageHistoryRef.current &&
      messageHistoryRef.current.scrollTop === 0 &&
      hasMoreMessages &&
      !isFetchingMessages
    ) {
      fetchMoreMessages();
    }
  };

  // 3. Fetch older messages
  const fetchMoreMessages = async () => {
    setIsFetchingMessages(true);
    try {
      const nextPage = messagePage + 1;
      const res = await axios.get(
        `${API_BASE_URL}/public/tickets/customer-view/messages`,
        {
          params: {
            ticket_id: ticket_id,
            page: nextPage,
            limit: 10,
          },
        }
      );

      const { messages, totalMessages } = res.data;

      // Reverse if needed (e.g., if server returns newest first)
      const reversedMessages = messages.reverse();

      if (messageHistoryRef.current) {
        const scrollPosition =
          messageHistoryRef.current.scrollHeight -
          messageHistoryRef.current.scrollTop;

        // Prepend older messages
        setMessageHistory((prev) => [...reversedMessages, ...prev]);
        setMessagePage(nextPage);

        // Keep scroll anchored
        setTimeout(() => {
          if (messageHistoryRef.current) {
            messageHistoryRef.current.scrollTop =
              messageHistoryRef.current.scrollHeight - scrollPosition;
          }
        }, 0);
      }

      if (messageHistory.length + messages.length >= totalMessages) {
        setHasMoreMessages(false);
      }
    } catch (err) {
      console.error("Error fetching more messages:", err);
      setHasMoreMessages(false);
    } finally {
      setIsFetchingMessages(false);
    }
  };

  // 4. Send new message
  const handleSendMessage = async () => {
    if (!ticket) {
      alert("No ticket found. Please reload the page.");
      return;
    }

    if (!messageContent.trim() && selectedFiles.length === 0) {
      alert("Please enter a message or attach at least one file.");
      return;
    }
    setIsSending(true);

    try {
      // Prepare a FormData to handle text + multiple files
      const formData = new FormData();
      formData.append("ticket_id", ticket_id); // The encrypted ID
      formData.append("content", messageContent);

      // Append all selected files
      selectedFiles.forEach((file) => {
        formData.append("documents", file);
      });

      const res = await axios.post(
        `${API_BASE_URL}/public/tickets/customer-view/send-message`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // The server returns the new message
      const { newMessage } = res.data;
      // Append to bottom
      setMessageHistory((prev) => [...prev, newMessage]);
      setMessageContent("");
      setSelectedFiles([]);

      // Scroll to bottom
      setTimeout(() => {
        if (messageHistoryRef.current) {
          messageHistoryRef.current.scrollTop =
            messageHistoryRef.current.scrollHeight;
        }
      }, 0);
    } catch (err) {
      console.error("Error sending message:", err);
      alert("Failed to send message.");
    } finally {
      setIsSending(false);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    const maxSize = 10 * 1024 * 1024; // 10 MB
    if (selectedFiles.length + files.length > 5) {
      alert("You can only attach up to 5 files in total.");
      return;
    }

    const validFiles = [];
    for (let file of files) {
      if (file.size > maxSize) {
        alert(`File "${file.name}" exceeds 10 MB limit and was not added.`);
      } else {
        validFiles.push(file);
      }
    }

    // Append to existing selectedFiles
    setSelectedFiles((prev) => [...prev, ...validFiles]);
  };

  // Remove a file from the selectedFiles array
  const removeFile = (index) => {
    setSelectedFiles((prev) => {
      const newArr = [...prev];
      newArr.splice(index, 1);
      return newArr;
    });
  };

  // Toggle the ticket details modal (mobile/tablet)
  const handleToggleDetails = () => {
    setShowDetailsModal((prev) => !prev);
  };

  // Render function for the ticket details (used in both desktop and modal)
  const renderTicketDetails = () => {
    if (!ticket) {
      return (
        <div className="loading-box-query-chat-customer">
          <div className="querybuttonloader-query-chat-customer"></div>
          <p>Loading Ticket Info...</p>
        </div>
      );
    }

    return (
      <div className="ticket-info-box-query-chat-customer">
        <div className="ticket-info-row-query-chat-customer">
          <div className="info-label-query-chat-customer">Ticket ID:</div>
          <div className="info-value-query-chat-customer">
            #{ticket.ticket_number}
          </div>
        </div>

        <div className="ticket-info-row-query-chat-customer">
          <div className="info-label-query-chat-customer">Full Name:</div>
          <div className="info-value-query-chat-customer">
            {ticket.user_full_name}
          </div>
        </div>

        <div className="ticket-info-row-query-chat-customer">
          <div className="info-label-query-chat-customer">Email:</div>
          {/* 
             Force wrap on any long text by adding 
             'word-break: break-all' or 'overflow-wrap: break-word' in the CSS 
          */}
          <div className="info-value-query-chat-customer">
            {ticket.user_email}
          </div>
        </div>

        <div className="ticket-info-row-query-chat-customer">
          <div className="info-label-query-chat-customer">Phone:</div>
          <div className="info-value-query-chat-customer">
            {ticket.user_phone}
          </div>
        </div>

        <div className="ticket-info-row-query-chat-customer">
          <div className="info-label-query-chat-customer">School:</div>
          <div className="info-value-query-chat-customer">
            {ticket.school_name}
          </div>
        </div>

        <div className="ticket-info-row-query-chat-customer">
          <div className="info-label-query-chat-customer">Category:</div>
          <div className="info-value-query-chat-customer">
            {ticket.category}
          </div>
        </div>

        <div className="ticket-info-row-query-chat-customer">
          <div className="info-label-query-chat-customer">Description:</div>
          <div className="info-value-query-chat-customer">
            {ticket.description}
          </div>
        </div>

        {ticket.document_url && (
          <div className="ticket-info-row-query-chat-customer">
            <div className="info-label-query-chat-customer">Document:</div>
            <div className="info-value-query-chat-customer">
              <a
                href={ticket.document_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            </div>
          </div>
        )}

        <div className="ticket-info-row-query-chat-customer">
          <div className="info-label-query-chat-customer">Status:</div>
          <div className="info-value-query-chat-customer">{ticket.status}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="query-customer-container-query-chat-customer">
      {/* Mobile/Tablet header */}
      <div
        className="mobile-header-query-chat-customer"
        onClick={handleToggleDetails}
      >
        <span>
          Ticket Details <small>(click to view details)</small>
        </span>

        {/* Only show 'X' if modal is open */}
        {showDetailsModal && (
          <button className="close-modal-btn" onClick={handleToggleDetails}>
            {/* Bold, black X icon */}
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="close-icon-bold close-modal-btn"
              onClick={handleToggleDetails}
            />
          </button>
        )}
      </div>

      {/* The modal (shown only on mobile/tablet when showDetailsModal=true) */}
      {showDetailsModal && (
        <div className="ticket-details-modal" onClick={handleToggleDetails}>
          <div
            className="ticket-details-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            {renderTicketDetails()}
          </div>
        </div>
      )}

      <div className="ticket-customer-view-query-chat-customer">
        {/* Desktop-only ticket details section */}
        <div className="ticket-info-section-query-chat-customer">
          <h2>Ticket Details</h2>
          {renderTicketDetails()}
        </div>

        {/* Right side: Chat & Messages */}
        <div className="message-history-query-chat-customer">
          <h3 className="message-history-title-query-chat-customer">
            Message History
          </h3>

          {/* Chat messages */}
          <div
            className={
              "messages-container-query-chat-customer" +
              (messageHistory.length === 0 && !isLoadingMessages
                ? " empty-query-chat-customer"
                : "")
            }
            ref={messageHistoryRef}
            onScroll={handleMessageScroll}
          >
            {isLoadingMessages ? (
              <div className="status-message-query-chat-customer">
                Loading messages...
              </div>
            ) : messageHistory.length === 0 ? (
              <div className="status-message-query-chat-customer">
                No messages found.
              </div>
            ) : (
              messageHistory.map((msg) => (
                <div
                  key={msg.message_id}
                  className={
                    "message-item-query-chat-customer " +
                    (msg.sender === ticket?.user_email
                      ? "sent-query-chat-customer"
                      : "received-query-chat-customer")
                  }
                >
                  <div className="message-sender-query-chat-customer">
                    {msg.sender_name}
                  </div>
                  <div className="message-content-query-chat-customer" dangerouslySetInnerHTML={{ __html: linkify(msg.content) }}>
                    
                  </div>
                  {msg.document_urls && msg.document_urls.length > 0 && (
                    <div className="message-attachments-query-chat-customer">
                      {msg.document_urls.map((url, index) => (
                        <a
                          key={index}
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="attachment-link-query-chat-customer"
                        >
                          Attachment {index + 1}
                        </a>
                      ))}
                    </div>
                  )}
                  <div className="message-timestamp-query-chat-customer">
                    {new Date(msg.sent_at).toLocaleDateString("en-GB")}{" "}
                    {new Date(msg.sent_at).toLocaleTimeString()}
                  </div>
                </div>
              ))
            )}
          </div>

          {/* Message input and send button (pinned at bottom on mobile/tablet) */}
          <div className="message-input-section-query-chat-customer">
          {selectedFiles.length > 0 && (
              <div className="floating-selected-files-list-query-chat-customer">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="selected-file-item-query-chat-customer">
                    <span className="selected-file-name-query-chat-customer">
                      {file.name}{" "}
                      <small>({(file.size / 1024 / 1024).toFixed(2)} MB)</small>
                    </span>
                    <button
                      type="button"
                      className="remove-file-btn-query-chat-customer"
                      onClick={() => removeFile(index)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                ))}
              </div>
            )}
            <textarea
              className="message-textbox-query-chat-customer"
              placeholder="Type your message..."
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
            />
            
            <div className="attachment-button-query-chat-customer">
              <input
                id="file-upload-query-chat-customer"
                type="file"
                multiple
                onChange={handleFileChange}
                className="file-upload-input-query-chat-customer"
              />
              <label
                htmlFor="file-upload-query-chat-customer"
                className="attachment-label-query-chat-customer"
              >
                <FontAwesomeIcon
                  icon={faFile}
                  className="attachment-icon-query-chat-customer"
                />
                {/* <span>Attach</span> */}
              </label>
            </div>
            <button
              className={
                "send-button-query-chat-customer" +
                (isSending ? " sending-query-chat-customer" : "")
              }
              onClick={handleSendMessage}
              disabled={isSending}
            >
              <div className="svg-wrapper-1-query-chat-customer">
                <div className="svg-wrapper-query-chat-customer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path
                      fill="currentColor"
                      d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                    ></path>
                  </svg>
                </div>
              </div>
              <span>{isSending ? "Sending..." : "Send"}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryChatCustomer;
