// src/socket.js
import { io } from 'socket.io-client';

// If your server runs on http://localhost:5000 (or a different domain):
const SOCKET_SERVER_URL = "http://97.74.82.197:5000";

const socket = io(SOCKET_SERVER_URL, {
  // If needed, pass in authentication or extra config here
  // e.g. "auth": { token: localStorage.getItem('token') }
});

export default socket;
