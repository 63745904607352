import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Contact Section */}
        <div className="footer-section">
          <h3 className="footer-heading">Contact Us</h3>
          <div className="underline"></div>
          <div className="contact-info">
            <div className="contact-item">
              <FontAwesomeIcon icon={faEnvelope} />
              <span>info@techvein.com</span>
            </div>
            {/* <div className="contact-item">
              <FontAwesomeIcon icon={faPhone} />
              <span>+91 9102993336 , +91 7903031125</span>
            </div> */}
            <div className="contact-item">
              <FontAwesomeIcon icon={faLocationDot} />
              <span>B-18 Sen Colony, Power House Road, Jaipur - 302006.</span>
            </div>
          </div>
        </div>

        {/* Logo Section */}
        <div className="footer-section logo-section">
          <div className="round-logo">
            <img src="/companyLogo.png" alt="Company Logo" />
          </div>
          <h3 className="company-name">
            Techvein IT Solutions Private Limited
          </h3>
        </div>

        {/* Social Media Section */}
        <div className="footer-section follow-us">
          <h3 className="footer-heading">Follow Us</h3>
          <div className="underline"></div>
          <div className="social-icons">
            <a href="https://www.facebook.com/Techvein0/" target="_blank" className="social-icon">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            {/* <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faInstagram} />
            </a> */}
            <a href="https://www.linkedin.com/company/techvein-it-solutions-pvt-ltd/" target="_blank" className="social-icon">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>
          © {new Date().getFullYear()}{" "}
          <a
            href="https://www.techvein.com/"
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            Techvein
          </a>
          . All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
